<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Crear giro">
          <giro-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import GiroForm from "@/components/Forms/GiroForm.vue";
import CCard from "@/components/CCard.vue";
export default {
  components: {
    GiroForm,
    CCard,
  },
};
</script>
