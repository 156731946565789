<template>
  <form @submit.prevent="saveOrUpdateGiro">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="giro" class="form-label">Nombre:</label>
      <input v-model="giro.name" class="form-control" id="giro" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/giros";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      giro: { name: "" },
      giroId: this.$route.params.id,
    };
  },
  mounted() {
    this.getGiro();
  },
  methods: {
    getGiro() {
      if (!this.giroId) {
        return;
      }
      find(this.giroId).then((response) => {
        this.giro = response.data;
      });
    },
    saveOrUpdateGiro() {
      if (this.giroId) {
        this.updateGiro();
      } else {
        this.saveGiro();
      }
    },
    saveGiro() {
      create(this.giro).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/giros");
      });
    },
    updateGiro() {
      update(this.giroId, this.giro).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/giros");
      });
    },
  },
};
</script>
